import * as React from "react";
import { Layout } from "../components/global/Layout";
import SEO from "../components/global/SEO";
import { Service } from "../components/services/Service";

const ServiceTemplate = ({ pageContext }) => {
  return (
    <Layout>
      <SEO />
      <Service slug={pageContext.slug} />
    </Layout>
  );
};

export default ServiceTemplate;
